import { createPlaceholder, createYoutubePlaceholder, createGMapPlaceholder } from './placeholders';

export default class ContextualDisplayer {
  constructor($holder, service) {
    this.$holder = $holder;
    this.service = service;

    this.displayPlaceholder();

    document.addEventListener('cookies:updateChoice', (e) => {
      if (e.detail[this.service]) {
        this.displayElement();
      } else {
        this.displayPlaceholder();
      }
    });
  }

  displayPlaceholder() {
    let html = '';

    switch (this.service) {
      case 'Youtube':
        html = createYoutubePlaceholder().outerHTML;
        break;
      case 'gmaps':
        html = createGMapPlaceholder().outerHTML;
        break;
      default:
        html = createPlaceholder(this.service, this.service).outerHTML;
        break;
    }

    this.$holder.innerHTML = html;
  }

  displayElement() {
    let html = '';

    switch (this.service) {
      case 'youtube':
        html = `
          <iframe data-service="youtube"
            width="560" height="315"
            src="${this.$holder.getAttribute('data-src')}"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `;
        break;
        case 'gmaps':
          html = `
            <iframe data-service="gmaps"
              width="560" height="315"
              src="${this.$holder.getAttribute('data-src')}"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          `;
          break;
      default:
        break;
    }

    this.$holder.innerHTML = html;
  }
}
